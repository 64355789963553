import React from 'react';
import './Footer.css';

import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
        Lucky Star Medical is Awaiting Approval For a SAPHRA License
        </p>
        
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Email Address</h2>
            
            <Link to='/services'>luckysmedical@gmail.com</Link>

          </div>
          <div class='footer-link-items'>
            <h2>Contact Number</h2>
            <Link to='/ContactUs'>064 738 3134</Link>
          </div>

          <div class='footer-link-items'>
            <h2>Director</h2>
            <Link to='/ContactUs'>Lucky Tshepho</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>


        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
            Lucky Star Medical Medical
            </Link>
          </div>
          <small class='website-rights'>Lucky Star Medical Medical © 2022</small>

        </div>
      </section>
    </div>
  );
}

export default Footer;

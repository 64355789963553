import React from 'react'
import { Link } from 'react-router-dom'
import './Button_Energy_Style.css'

function ButtonEnergy(props) {
    return (
        <div className='container'>
            <Link className='linkHref' to={props.url}><span className='spanmain'>{props.title}</span></Link>
            
            
        </div>
    )
}

export default ButtonEnergy